import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { rgba, darken } from 'polished'
import Image from 'src/components/Image'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

import Boathouse from 'src/assets/images/challenges/slideshow/boathouse.svg'
import Cherry from 'src/assets/images/challenges/slideshow/cherry.svg'
import Fdr from 'src/assets/images/challenges/slideshow/fdr.svg'
import George from 'src/assets/images/challenges/slideshow/george.svg'
import Jefferson from 'src/assets/images/challenges/slideshow/jefferson.svg'
import Jones from 'src/assets/images/challenges/slideshow/jones.svg'
import Lantern from 'src/assets/images/challenges/slideshow/lantern.svg'
import Mlk from 'src/assets/images/challenges/slideshow/mlk.svg'
import Pagoda from 'src/assets/images/challenges/slideshow/pagoda.svg'
import Walkways from 'src/assets/images/challenges/slideshow/walkways.svg'

import Rectangles from 'src/assets/images/challenges/slideshow/Rectangles.svg'
import ArrowLeft from 'src/assets/images/arrow_left.svg'
import ArrowRight from 'src/assets/images/arrow_right.svg'


import { colors, mediaQueries as mq, animations, util } from 'src/styles'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

const thumbnailGutter = 10

// const StyledSlider = styled(Slider)`
// * {transition: height ${ animations.mediumSpeed } ease-in-out;}
// 	.slick-slide > div {
// 		display: flex;
// 		justify-content: center;
// 	}
// `

const SectionWrapper = styled(Section)`
`

const Wrapper = styled.div`
	display: flex;
	padding-top: 0;
	padding-bottom: 50px;
	position: relative;
`

const SlideshowSection = styled.div`
	position: relative;
	flex-grow: 1;
	${ mq.smallAndBelow } {
    overflow: auto;
  }
`

const CustomSlider = styled(Slider)`

`

const SlideshowWrapper = styled.div`
	max-width: 100%;
	overflow: hidden;
	${ mq.mediumAndBelow } {
		max-width: unset;
    width: 225%;
  }
`

const Slide = styled.div`
`

const SlideImageWrapper = styled.div`

`

const ImageCreditGrid = styled(Grid)`

`

const ImageCaptionWrapper = styled.div`
`

const MobileSeaLevels = styled(Rectangles)`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: auto;
`

const MobileDiv = styled.div`
	padding-bottom: 58.8%;
	position: relative;
	svg {
	  transition: transform 0.5s ease;
		transform:	translateY(${({offset}) => offset}%);
	}
`



const NextPrevButton = styled(Button)`
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin: 0;
	z-index: 3;
	min-height: 32px;
	min-width: 32px;
	background: transparent !important;
	border: transparent !important;
	${ ({ position, dots }) => position === 'left' ? `
		left: 20px;
		${ dots ? util.responsiveStyles('margin-top', -25, -20, -18, -17) : `` }
	` : `
		right: 20px;
		${ dots ? util.responsiveStyles('margin-top', -25, -20, -18, -17) : `` }
	`}
	${ mq.smallAndBelow } {
		display: none;
	}

	svg {
		width: 18px;
		height: auto;

	}

	svg * {
		fill: none !important;
		transition: stroke 0.3s ease;
	}

	&:hover {
		svg * {
			stroke: ${colors.textColor};
		}
	}

`

const StyledFlexDiv = styled.div`
	flex-grow: 1;
	position: relative;
	width: 1px;
`

const StyledHeader = styled.h1`
	color: ${colors.white};
`

const OverlaySection = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	${ mq.smallAndBelow } {
		width: 200%;
	}
`

const OverlayGrid = styled(Grid)`
	height: 100%;
	// background: bisque;
`

const OverlayHeader = styled.h1`
	position: absolute;
	top: 50px;
	left: 0;
	max-width: 30rem;
`

const HorizontalRule = styled.div`
	position: absolute;
	top: 60.5%;
	left: 0;
	right: 0;
	width: 100%;
	border-bottom: 1px dashed ${colors.textColor};
`


const AllIconsWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`

const IconWrapper = styled.div`
	display: inline-block;
	position: absolute;
	bottom: ${props => props.bottom }%;
	left: ${props => props.left }%;
	svg {
		width: 6vw;
		height: auto;
		* {
			fill: ${colors.textColor};
		}
	}
	display: flex;
	flex-direction: column;
	${ mq.smallAndBelow } {
		bottom: ${props => props.bottomMobile }%;
		left: ${props => props.leftMobile }%;
		svg {
			width: 12vw;
			height: auto;
			* {
				fill: ${colors.textColor};
			}
		}
	}

`
const IconLabel = styled.p`
	max-width: 8em;
	font-size: 12px;
	line-height: 14px;
	margin-top: 5px;
	margin-bottom: 0;
	text-align: center;
	// align-self: flex-end;
	// max-width: 6vw;
	position: absolute;
	top: 100%;
	width: 100%;
	${ mq.smallAndBelow } {
		font-size: 10px;
	}


`

const Legend = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50%;
	height: 100%;
`

const LegendSpan = styled.span`
	font-size: 9px;
	${ ({ offset }) => offset != null ? `
		&.flood {
			position: absolute;
			bottom: ${ 57.5 +  offset }%;
			left: 2px;
		}
		&.high {
			position: absolute;
			bottom: ${ 32.8 + offset }%;
			left: 2px;
		}

		&.low {
			position: absolute;
			bottom: ${ 21 + offset }%;
			left: 2px;
		}
  ` :  `` }

  transition: bottom 0.5s ease;

	&.edge {
		position: absolute;
		bottom: 39%;
		left: 2px;
	}

	&.depth {
		position: absolute;
		bottom: -3px;
		left: 2px;
	}
`

const GuideWrapper = styled.div`
	width: 100px;
	flex-grow: 0;
	flex-shrink: 0;
	// background: red;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 1.75%;
	// padding-bottom: 0.25%;
	position: relative;
	${ mq.smallAndBelow } {
		width: 55px;
	}
`

const Elevation = styled.p`
	font-size: 12px;
	position: absolute;
	top: calc(50% - 10px);
	left: 20px;
  transform: rotate(-90deg);
  ${ mq.smallAndBelow } {
		left: -5px;
	}
`

const NotchWrapper = styled.div`
	width: 100%;
	padding-right: 2px;
	padding-left: 70%;
	p {
		margin-top: 0;
		margin-bottom: 0;
		align-self: flex-end;
		color: ${colors.textColor};
		font-size: 12px;
		text-align: right;
		line-height: 5px;
		display: flex;
		flex-direction: row;
	}
	p:after { 
    content: ""; 
    flex: 1 1;
    border-bottom: 1px solid ${colors.textColor}; 
    margin: auto; 
  }
  ${ mq.smallAndBelow } {
		padding-left: 60%;

		p {
			font-size: 10px;
		}
	}
`

const MobileButtonWrap = styled.div`
	position absolute;
	bottom: 0;
	padding: 0 50px 0 55px;
	// padding-left: 100px;
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const MobileButton = styled(Button)`
	// width: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	// background: transparent;
	// border-color: transparent;
	// color: ${colors.textColor};
	
	padding-left: 8px;
	padding-right: 8px;
	&:hover {
		background: ${colors.textColor};
		color: ${colors.white};
		border-color: ${colors.textColor};
	}

	${ mq.smallAndBelow } {
		font-size: 10px;
	}

`

const SlideOneHeader = styled.h1`
	position: absolute;
	top: 0;
	left: 30px;
	max-width: 35rem;
`

const SlideOneLegend = styled.p`
	position: absolute;
	bottom: ${props => props.bottom }%;
	left: 15px;
	font-size: 12px;
	max-width: 8rem;
	line-height: 20px;
`
const SlideshowCaption = styled.p`
	position: absolute;
	top: 100%;
	font-size: 10px;
	left: ${ 100 / 14 * 2 }vw;
`
class ChallengesSlideshow extends Component {
	constructor(props) {
    super(props);
    this.state = {
      mobileLevel: 'today'
    };
  }

	goToNextSlide = () => {
		if (this.slideshow) {
			this.slideshow.slickNext()
		}
	}

	goToPrevSlide = () => {
		if (this.slideshow) {
			this.slideshow.slickPrev()
		}
	}

	updateMobileLevel = (mobileLevel) => {
		this.setState({mobileLevel: mobileLevel})
	}

	componentDidMount () {
  }

	render () {

		const {
		className,
		id,
		index,
		isFirstSection,
		isLastSection,
		nextTheme,
		pageType,
		prevTheme,
		slides,
		prevIcon,
		nextIcon,
		pauseOnHover = true,
		autoplaySpeed = 3000,
		speed = 750,
		fade = false,
		autoplay = false
		} = this.props

		if (!slides && !children) {
			return false
		}

		if (!slides) {
			let slides = children
		}

		const settings = {
			dots: false,
			arrows: false,
			infinite: false,
			speed: speed,
			fade: false,
			autoplay: false,
			autoplaySpeed: autoplaySpeed,
			pauseOnHover: false,
			adaptiveHeight: false,
			slidesToShow: 1,
			// variableWidth: true,
			// appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
	    customPaging: i => (
	      <SlideshowDot><span/></SlideshowDot>
	    )
		}

		const MobileLevelHeights = {
			today: 38,
			forty: 26,
			seventy: 14,
			hundred: 2
		}

		const MobileLegendHeights = {
			today: {
				low: 0,
				high: 0,
				flood: 0
			},
			forty: {
				low: 11.5,
				high: 12,
				flood: 12
			},
			seventy: {
				low: 23.8,
				high: 24,
				flood: 24
			},
			hundred: {
				low: 35.8,
				high: 36,
				flood: 36
			}
		}

		// console.log("LEVELS:", MobileLevelHeights[this.state.mobileLevel])

		return (
			<Wrapper>
				<GuideWrapper>
					<Elevation>Elevation</Elevation>
					<NotchWrapper><p>18'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>16'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>14'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>12'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>10'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>8'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>6'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>4'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>2'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>0'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>-2'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>-4'&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>&nbsp;&nbsp;&nbsp;&nbsp;</p></NotchWrapper>
					<NotchWrapper><p>-6'</p></NotchWrapper>
				</GuideWrapper>
	
				<StyledFlexDiv>
					<SlideshowSection className={className}>
						
						<ResponsiveComponent 
							small={
									<SlideshowWrapper>
										<MobileDiv offset={MobileLevelHeights[this.state.mobileLevel]}> 
											<MobileSeaLevels/>
										</MobileDiv>
									</SlideshowWrapper>
							}
							large={
								<Fragment>
									<SlideshowWrapper>
										{slides.length > 1 ? (
											<CustomSlider
												ref={slider => (this.slideshow = slider)}
												{...settings}
											>
												{slides && slides.map((slide, index) => (
													<div key={index}>
														<SlideImageWrapper>
															<Image
																key={index}
																image={slide.image}
																loading={index === 0 ? "lazy" : "eager"}
															/>
														</SlideImageWrapper>
														{index === 0 ? (
															<Fragment>
															<SlideOneLegend bottom={52}>Annual flood</SlideOneLegend>
															<SlideOneLegend bottom={35.5}>Average Tidal Basin Hard Edge</SlideOneLegend>
															<SlideOneLegend bottom={28.5}>High tide</SlideOneLegend>
															<SlideOneLegend bottom={17}>Low tide</SlideOneLegend>
															<SlideOneLegend bottom={0}>Current Basin Depth</SlideOneLegend>
															</Fragment>
														) : false }
													</div>
												))}
											</CustomSlider>
										) : (
											<Slide>
												<Image image={slides[0]} />
											</Slide>
										)}
									</SlideshowWrapper>

									{slides.length > 1 && (
										<Fragment>
											<NextPrevButton shape="square" className="prev-button" size="small" setTheme="textColor" onClick={this.goToPrevSlide} position="left">{prevIcon ? prevIcon : <ArrowLeft />}</NextPrevButton>
											<NextPrevButton shape="square" className="next-button" size="small" setTheme="textColor" onClick={this.goToNextSlide} position="right">{nextIcon ? nextIcon : <ArrowRight />}</NextPrevButton>
										</Fragment>
									)}
								</Fragment>
							}
						/>

						<OverlaySection>
							<IconWrapper bottom={24} left={10} bottomMobile={24.5} leftMobile={7}>
								<Walkways/>
								<IconLabel>Walkways</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={30} left={18} bottomMobile={31} leftMobile={17}>
								<Cherry/>
								<IconLabel>Cherry trees</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={35.5} left={26} bottomMobile={37} leftMobile={27}>
								<Jefferson/>
								<IconLabel>Jefferson Memorial </IconLabel>
							</IconWrapper>

							<IconWrapper bottom={35.8} left={34} bottomMobile={37.4} leftMobile={37}>
								<Pagoda/>
								<IconLabel>Japanese pagoda</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={38} left={42} bottomMobile={40} leftMobile={47}>
								<Mlk/>
								<IconLabel>MLK Jr. Memorial</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={45.5} left={50} bottomMobile={48} leftMobile={57}>
								<Boathouse/>
								<IconLabel>Boat house</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={46.3} left={58} bottomMobile={50} leftMobile={67}>
								<Lantern/>
								<IconLabel>Japanese lantern</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={51.2} left={66} bottomMobile={54} leftMobile={77}>
								<Jones/>
								<IconLabel>Jones Memorial</IconLabel>
							</IconWrapper>

							<IconWrapper bottom={55} left={74} bottomMobile={57.8} leftMobile={87}>
								<Fdr/>
								<IconLabel>FDR Memorial</IconLabel>
							</IconWrapper>
							<IconWrapper bottom={56} left={82} bottomMobile={59} leftMobile={97}>
								<George/>
								<IconLabel>George Mason Memorial</IconLabel>
							</IconWrapper>

							<ResponsiveComponent 
								small={
										<Legend>
											<LegendSpan className="flood" offset={ MobileLegendHeights[this.state.mobileLevel].flood}>Annual flood</LegendSpan>
											<LegendSpan className="edge">Average Tidal Basin Hard Edge</LegendSpan>
											<LegendSpan className="high" offset={ MobileLegendHeights[this.state.mobileLevel].high}>High tide</LegendSpan>
											<LegendSpan className="low" offset={ MobileLegendHeights[this.state.mobileLevel].low}>Low tide</LegendSpan>
											<LegendSpan className="depth">Current Basin Depth</LegendSpan>
										</Legend>
								}
								large={<span/>}
							/>

						</OverlaySection>
					</SlideshowSection>

					<ResponsiveComponent 
						small={
								<HorizontalRule/>
						}
						large={<span/>}
					/>

				</StyledFlexDiv>
				<ResponsiveComponent 
						small={
							<Fragment>
								<MobileButtonWrap>
									<MobileButton 
										label="Today"
										onClick={() => this.updateMobileLevel('today')}
										size="tiny"
										setTheme={this.state.mobileLevel === 'today' ? "textColor" : "bgColorAlt"}

									/>

									<MobileButton 
										label="2040"
										onClick={() => this.updateMobileLevel('forty')}
										size="tiny"
										setTheme={this.state.mobileLevel === 'forty' ? "textColor" : "bgColorAlt"}
									/>

									<MobileButton 
										label="2070"
										onClick={() => this.updateMobileLevel('seventy')}
										size="tiny"
										setTheme={this.state.mobileLevel === 'seventy' ? "textColor" : "bgColorAlt"}
									/>

									<MobileButton 
										label="2100"
										onClick={() => this.updateMobileLevel('hundred')}
										size="tiny"
										setTheme={this.state.mobileLevel === 'hundred' ? "textColor" : "bgColorAlt"}
									/>
								</MobileButtonWrap>
								<SlideshowCaption>These are projections if no preventative action is taken.<br/>Source: NOAA</SlideshowCaption>
							</Fragment>
						}
						large={<span/>}
					/>
			</Wrapper>
		)
	
	}
}

export default ChallengesSlideshow
